import React, { Component } from 'react';
import '../css/toast_handler.scss'
import PubSub from "../pubsub";

class ToastHandler extends Component {

    constructor(props){
        super(props);
        this.state={
            toastMessages: []
        }
    }

    render(){
        return (
            <div className={"toast-container " + (this.state.toastMessages.length > 0? "visible":"") } id="toast_container">
                {this.state.toastMessages}
            </div>
        );
    }

    componentDidMount(){
        PubSub.subscribe.new_toast_message((event, info)=>{
              let element = this.ToastComponent(info)
              this.setState((state)=>({
                toastMessages: state.toastMessages.concat([element])
            }))

            setTimeout(()=>{
                document.getElementById(element.key).style.display= "none";
            }, ((info.type === 'success'?4:9) * 1000))
            
        })
    }

    ToastComponent(props){
        let index = document.getElementById('toast_container').children.length
        return(
            <div id={'toast_' + index} key={'toast_' + index} className={"toast-component " + props.type + (props.pathname !== ""? "":"")}>
                <div className='toast-header'>
                    <img alt={props.type + ' message'} src={"https://cc-cdn.pss.co/assets/" + props.type + ".svg"}/>
                    <h4><b>{props.header}</b></h4>
                </div>
                {props.message !== "" &&
                    <span className="p2">
                        {props.message}
                    </span>
                }
            </div>
        );
    }
}


export default ToastHandler;