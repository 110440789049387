var PubSub = require('pubsub-js');

const constants = {
    QUOTE_FORM_SUBMITTED: "QUOTE_FORM_SUBMITTED",
    IS_LOADING: "IS_LOADING",
    NEW_TOAST_MESSAGE: "NEW_TOAST_MESSAGE",


}

var pub_function = function (event) {
    return function (data) {
        return PubSub.publish(event, data);
    }
}

var sub_function = function (event) {
    return function (callback) {
        return PubSub.subscribe(event, callback);
    }
}


var publish = {
    quote_form_submitted: pub_function(constants.QUOTE_FORM_SUBMITTED),
    is_loading: pub_function(constants.IS_LOADING),
    new_toast_message: pub_function(constants.NEW_TOAST_MESSAGE)
}

var subscribe = {
    quote_form_submitted: sub_function(constants.QUOTE_FORM_SUBMITTED),
    is_loading: sub_function(constants.IS_LOADING),
    new_toast_message: sub_function(constants.NEW_TOAST_MESSAGE)
}

module.exports = {
    publish: publish,
    subscribe: subscribe,
}
