import React from "react";
import ContactComponent from "../views/ContactComponentView";

class ContactComponentController extends React.Component{
    state = {}
    render(){
        return(
        <ContactComponent>
            <email-link href="mailto:support@pssk12.com"/>
            <phone-link href="tel:+18004859722"/>
        </ContactComponent>)
    }

}

export default ContactComponentController