import React, { Component,} from "react";
import {Routes, Route} from 'react-router-dom'

import SuccessController from "./controllers/SuccessController";
import IndexController from "./controllers/IndexController";
import QuotePageController from "./controllers/QuotePageController";

import './styles'
import './scripts'

class App extends Component{
    render(){
        return(
            <Routes>
                <Route exact path='/' element={<IndexController/>}/>
                <Route path="/create-quote" element={<QuotePageController/>}/>
                <Route path="/success" element={<SuccessController/>}/>
            </Routes>
        );
    }

    componentDidMount(){
      window.scrollTo(window.scrollX, window.scrollY + 1);    
    }
}

export default App;
