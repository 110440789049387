/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import ContactComponentView from './ContactComponentView'
import NavComponentView from './NavComponentView'
import FooterContainerSmView from './FooterContainerSmView'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=644a976732488f10065f1cde").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class SuccessView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/SuccessController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = SuccessView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '644a976732488f4efe5f1cdf'
    htmlEl.dataset['wfSite'] = '644a976732488f10065f1cde'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = SuccessView.Controller !== SuccessView ? transformProxies(this.props.children) : {
      'success-header': [],
      'success-body': [],
      'success-ctas': [],
      'quote-cta': [],
      'invoice-cta': [],
      'contact-us-link': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/pss-quotes.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-success-view">
            <ContactComponentView.Controller />
            <NavComponentView.Controller />
            <FooterContainerSmView.Controller />
            <div className="af-class-section-12">
              <div className="af-class-container-25 w-container"><img src="images/checklist.png" loading="lazy" width={195} sizes="(max-width: 479px) 80vw, 195px" alt srcSet="images/checklist-p-500.png 500w, images/checklist-p-800.png 800w, images/checklist.png 814w" />
                <div className="af-class-div-block-56">
                  {map(proxies['success-header'], props => <div {...{...props, className: `af-class-h4 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Your quote was successfully submitted!</React.Fragment>}</div>)}
                  {map(proxies['success-body'], props => <p {...{...props, className: `af-class-p2-2 af-class-space ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Your assigned representativewill be in thouch with you shortly and help you solve any other doubts! </React.Fragment>}</p>)}
                  {map(proxies['success-ctas'], props => <div {...{...props, className: `af-class-div-block-92 ${props.className || ''}`}}>{createScope(props.children, proxies => <React.Fragment>
                    {map(proxies['quote-cta'], props => <a href="#" {...{...props, className: `af-class-white-button-3 af-class-cta w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Download Quote</React.Fragment>}</a>)}
                    {map(proxies['invoice-cta'], props => <a href="#" {...{...props, className: `af-class-blue-button-3 af-class-cta w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Purchase Now</React.Fragment>}</a>)}
                  </React.Fragment>)}</div>)}
                  <div className="af-class-caption-2">Do you still have questions? {map(proxies['contact-us-link'], props => <a href="#" {...{...props, className: `af-class-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Contact Us</React.Fragment>}</a>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      </span>
    )
  }
}

export default SuccessView

/* eslint-enable */