import React from "react";
import FooterContainerSmView  from "../views/FooterContainerSmView";

class FooterContainerSmController extends React.Component{
    state = {}
    render(){
        return(
        <FooterContainerSmView>
            <pp-link onClick={()=>{
                window.open("https://cc-cdn.pss.co/public/PSS%20Privacy%20Policy.pdf","_blank")
            }}/>
            <tos-link onClick={()=>{
                window.open("https://cc-cdn.pss.co/public/PSS%20TOS.pdf","_blank")
            }}/>
            <support-link onClick={()=>{
                window.location.href = "mailto:support@pssk12.com";
            }}>support@pssk12.com</support-link>
        </FooterContainerSmView>)
    }

}

export default FooterContainerSmController
