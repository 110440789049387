/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import ContactComponentView from './ContactComponentView'
import NavComponentView from './NavComponentView'
import FooterContainerSmView from './FooterContainerSmView'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=644a976732488f10065f1cde").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class QuotePageView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/QuotePageController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = QuotePageView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '644a9f7a7029722ce1b7ba25'
    htmlEl.dataset['wfSite'] = '644a976732488f10065f1cde'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = QuotePageView.Controller !== QuotePageView ? transformProxies(this.props.children) : {
      'pm-learn-more': [],
      'promo-body': [],
      'promo-container': [],
      'promo-error': [],
      'pm-supplies-quote': [],
      'supplies-container': [],
      'installation-container': [],
      'warranty-container': [],
      'folio-learn-more': [],
      'folio-container': [],
      'total-value': [],
      'submit-button': [],
      'contact-button': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/pss-quotes.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body af-class-quote-view">
            <ContactComponentView.Controller />
            <NavComponentView.Controller />
            <div className="af-class-container-20 w-container">
              <div className="af-class-columns-10 w-row">
                <div className="w-col w-col-6">
                  <div id="w-node-d4c064a7-b735-1324-e98f-c1df0f008d30-e1b7ba25" className="af-class-div-block-62"><img id="w-node-d4c064a7-b735-1324-e98f-c1df0f008d31-e1b7ba25" sizes="(max-width: 767px) 48vw, (max-width: 991px) 354px, 345px" src="images/PosterMakerpsslogo.png" srcSet="images/PosterMakerpsslogo-p-500.png 500w, images/PosterMakerpsslogo.png 740w" alt="PSS Poster Maker System" className="af-class-image-6" /></div>
                </div>
                <div className="w-col w-col-6">
                  <div id="w-node-d4c064a7-b735-1324-e98f-c1df0f008d33-e1b7ba25" className="af-class-div-block-39">
                    <h1 id="w-node-d4c064a7-b735-1324-e98f-c1df0f008d34-e1b7ba25" className="af-class-h4 af-class-mobile">Customize your <br />Poster Maker System</h1>
                    {map(proxies['pm-learn-more'], props => <div {...{...props, className: `af-class-text-block-73 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Learn more about the Poster Maker</React.Fragment>}</div>)}
                    <div className="af-class-div-block-38">
                      <div className="af-class-h9 af-class-list">Includes:</div>
                      <div className="af-class-h9 af-class-list af-class-black-10">- 24” Poster Maker Printer<br />- PC Workstation (w/ Software Included)<br />- One Year PSS Care Warranty<br />- One Year of Folio Starter Access<br />- High-resolution Scanner<br />- Starter Ink Set<br /></div>
                      {map(proxies['promo-body'], props => <div {...{...props, className: `af-class-h9 af-class-list af-class-promo ${props.className || ''}`}}>{props.children}</div>)}
                      {map(proxies['promo-container'], props => <div {...{...props, className: `af-class-div-block-91 ${props.className || ''}`}}>{props.children}</div>)}
                      {map(proxies['promo-error'], props => <div {...props}>{props.children}</div>)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="w-container">
                <div className="af-class-form-block-3 w-form">
                  <form id="email-form" name="email-form" data-name="Email Form" method="get" className="af-class-hack42-45-form af-class-_80" data-wf-page-id="644a9f7a7029722ce1b7ba25" data-wf-element-id="7bcb120e-9926-a0a6-9e33-61705a356f28">
                    <div className="af-class-card-03 af-class-_50-p af-class-r1 af-class-_80">
                      <div className="af-class-line" />
                      <div className="af-class-div-title-holder af-class-image af-class-s-o">
                        <div className="af-class-icon-title"><img alt="Supply Options" loading="lazy" src="https://uploads-ssl.webflow.com/63dabbb2d238ed7213920cfb/63fe197618a61d9a111c5dea_inks%20icon.svg" className="af-class-image-8" />
                          <h4 className="af-class-h6">Supply Options</h4>
                        </div>
                        {map(proxies['pm-supplies-quote'], props => <div {...{...props, className: `af-class-text-block-73 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Only looking for Supplies?</React.Fragment>}</div>)}
                      </div>
                      {map(proxies['supplies-container'], props => <div {...props}>{props.children}</div>)}
                      <div className="af-class-line" />
                      <div className="af-class-div-title-holder af-class-image">
                        <div className="af-class-icon-title"><img alt="Training and Installation" loading="lazy" src="images/installation.svg" className="af-class-image-9" />
                          <h4 className="af-class-h6">Training and Installation</h4>
                        </div>
                      </div>
                      {map(proxies['installation-container'], props => <div {...props}>{props.children}</div>)}
                      <div className="af-class-line" />
                      <div className="af-class-div-title-holder af-class-image">
                        <div className="af-class-icon-title"><img width={28} alt="PSS Care Warranty" src="images/warranty.svg" loading="lazy" className="af-class-image-11" />
                          <h4 className="af-class-h6">PSS Care Warranty</h4>
                        </div>
                        <div className="af-class-p5 af-class-description">PSS Care protects your Poster Maker System investment!. The PSS Care plan includes parts, on-site service visits, phone support, training, and a replacement printer if we are unable to fix your machine. Extended warranty options are recommended to further protect your system.</div>
                      </div>
                      {map(proxies['warranty-container'], props => <div {...props}>{props.children}</div>)}
                      <div className="af-class-line" />
                      <div className="af-class-div-title-holder af-class-image af-class-_02 af-class-top">
                        <div className="af-class-icon-title"><img width={48} alt="Folio" src="https://uploads-ssl.webflow.com/63dabbb2d238ed7213920cfb/63fe1976a852696d83d7c3ca_folio%20icon.svg" loading="lazy" className="af-class-image-10" />
                          <h4 className="af-class-h6">Folio (K-8)</h4>
                        </div>
                        <div className="af-class-p5 af-class-description">Folio is an educational collection of resources designed to engage young learners (K-8) and work with your Poster Maker System! Created by a team of experienced teachers and designers, Folio content is both academically rigorous and well-designed. All content is also available in English and Spanish variants to support ELL students! {map(proxies['folio-learn-more'], props => <strong id="folio-learn-more-link" {...{...props, className: `af-class-bold-text ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Learn More!</React.Fragment>}</strong>)}</div>
                      </div>
                      {map(proxies['folio-container'], props => <div {...props}>{props.children}</div>)}
                    </div>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="af-class-sticky-footer">
                <div className="af-class-sticky-footer-info">
                  <div className="af-class-div-block-67">
                    {map(proxies['total-value'], props => <div {...{...props, className: `af-class-h6 af-class-number ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>$4,899</React.Fragment>}</div>)}
                  </div>
                  {map(proxies['submit-button'], props => <div {...{...props, className: `af-class-blue-button-2 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>
                    <div className="af-class-text">Create a Quote</div>
                  </React.Fragment>}</div>)}
                  {map(proxies['contact-button'], props => <a href="#" {...{...props, className: `af-class-white-button w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Contact Us</React.Fragment>}</a>)}
                </div>
              </div>
            </div>
            <FooterContainerSmView.Controller />
          </div>
        </span>
      </span>
    )
  }
}

export default QuotePageView

/* eslint-enable */