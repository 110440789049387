import React, { Component } from 'react';
import '../css/form_popup.scss';
import HubspotForm from 'react-hubspot-form';
import PubSub from '../pubsub';

class FormPopup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: true,
        }
    }

    render() {
        return (
            <div id="contact_popup" className="contact-popup">
                <div className="contact-background"></div>
                <div className="contact-form">
                    <div className="contact-header">
                        <div className="header-row">
                            {/*<img className="lazyload" alt="" data-srcset="https://cdn.pss.co/assets/icons/wave_icon.svg"></img>*/}
                            <div className="header-content">
                                <h2 >Get your quote today!</h2>
                                <p>Before we can get started customizing your Poster Maker System quote we need some information first.</p>
                            </div>
                        </div>
                        <div className="success-content">
                            <img alt="" src="https://cdn.pss.co/assets/icons/check_mark_icon.svg"/>
                            <h2>Thank you!</h2>
                        </div>
                    </div>
                    {/*[if lte IE 8]*/}
                    
                    <HubspotForm
                        portalId='7314258'
                        formId='e0b05783-d318-44a2-86be-52855eb9c032'
                        loading={<div>Loading...</div>}
                        className="hubspot-form-container"
                        onSubmit={(e)=>{
                            var formData = e.reduce((acc, obj)=>{
                                acc[obj.name] = obj.value
                                return(acc)
                            },{})
                            PubSub.publish.quote_form_submitted(formData);
                        }}
                    >
                    </HubspotForm>
                </div>
            </div>
        );
    }
}
export default FormPopup;
