import PubSub from "../pubsub";
function handleError(){
    PubSub.publish.new_toast_message({
        type: 'error',
        header: "Oops! Something went wrong",
        message:  "Please try again in a few moments or contact us directly!"
    })
}

let helperFunctions = {
    handleError
}

export default helperFunctions;