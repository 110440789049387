import React from "react";
import QuotePageView from "../views/QuotePageView";
import '../css/quote_view.scss';
import FormPopup from "../components/FormPopup"
import PubSub from "../pubsub";
import axios from 'axios';
import helperFunctions from "../tools/helperFunctions";
import '../css/_base.scss';


let quoteOptions, installPrices;

if (process.env.REACT_APP_ENV === "prod") {
  quoteOptions = require("../quote_options.json");
  installPrices = require("../install_prices.json");
} else {
  quoteOptions = require("../quote_options_dev.json");
  installPrices = require("../install_prices_dev.json");
}


const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const taxableStates = ['NC', 'SC', 'CA'];

const baseURL = process.env.REACT_APP_BASE_URL;

class QuotePageController extends React.Component{
    state = {
        supplies: quoteOptions.supplies.findIndex(item => item.default === true),
        installation: 0,
        warranty: quoteOptions.warranty.findIndex(item => item.default === true),
        folio: quoteOptions.folio.findIndex(item => item.default === true),
        total: 0,
        formData: null,
        stateIndex: 0,
        promoInput: "",
        promoError: "",
        coupon: null
    }
    render(){
        return(
        <>
            <QuotePageView className="no-scroll">
                <promo-body className="promo-body">{(this.state.coupon? "- " +this.state.coupon.name:"")}</promo-body>
                <promo-container className="promo-container">
                    <input 
                            id="promo_input"
                            className={"promo-input " + (this.state.coupon?"disabled":"")} 
                            type="text"
                            placeholder="Promo code here"
                            maxLength={15}
                            value={this.state.promoInput}
                            onChange={(e)=>{
                                    this.setState({ 
                                        promoError: "",
                                        promoInput: e.target.value });

                            }}
                            onKeyDown={(e)=>{
                                if (e.key === 'Enter') {
                                    this.getCoupon()
                                }
  
                            }}      
                        />
                    <button className={"promo-button " + (this.state.coupon?"disabled":"")} onClick={()=>{this.getCoupon()}}>Add</button>
                </promo-container>
                <promo-error className="promo-error">{this.state.promoError}</promo-error>
                <pm-learn-more className="gray-link-button" onClick={()=>{
                    window.open("https://pssk12.com/poster-maker", "_blank")
                }}></pm-learn-more>
                <pm-supplies-quote onClick={()=>{
                    window.open("https://shop.pssk12.com", "_blank")
                }}>

                </pm-supplies-quote>
                <supplies-container className="card-container">
                    { quoteOptions.supplies.map((item, index)=>{
                    return(this.createCard(item, index, "supplies"))
                    })
                    }
                </supplies-container>
                <installation-container className="card-container">
                    { (this.state.formData !== null) && 
                        this.createCard(quoteOptions.installation[this.state.installation], this.state.installation, "installation")
                    }

                </installation-container>
                <warranty-container >
                    <fieldset className="radio-container">
                        {quoteOptions.warranty.map((item, index)=>{
                            return(this.createListOption(item, index, "warranty"))
                        })}
                    </fieldset>
                </warranty-container>
                <folio-container>
                    <fieldset className="radio-container">
                        {quoteOptions.folio.map((item, index)=>{
                            return(this.createListOption(item, index, "folio"))
                        })}
                    </fieldset>
                </folio-container>
                 <folio-learn-more className="link-button" onClick={()=>{
                    window.open("https://foliok12.com/library","_blank")
                }}></folio-learn-more>

                {(this.state.formData !== null)  &&
                    <total-value>
                        <span className="h6">
                            {(taxableStates.includes(this.state.formData.quote_state)?"Subtotal: ":"Total: ") + formatter.format(this.state.total)}
                        </span>
                    </total-value>
                }
                <submit-button onClick={()=>{this.submitQuote()}}></submit-button>
                <contact-button ></contact-button>
            </QuotePageView>
            {this.state.formData === null && <FormPopup/>}
        </>
        )
    }

    createCustomer(){
        PubSub.publish.is_loading(true)

        const url = 'customer/create-customer';
        
        const customerData  = { 
            first_name: this.state.formData.firstname, 
            last_name: this.state.formData.lastname,
            email: this.state.formData.email,
            school: this.state.formData.company,
            state: this.state.formData.quote_state,
            zip_code: this.state.formData.zip
        };

        axios({
            headers:{
            'Content-Type': 'application/json',
            'Authorization': "None",
            },
            method: "POST",
            url: baseURL + url,
            data: JSON.stringify(customerData),
        })
        .then(response => {
            PubSub.publish.is_loading(false)

            var stateIndex = installPrices.findIndex(item => item.state === customerData.state)
            this.setState(()=>({
                installation: (installPrices[stateIndex].installation?0:1),
                stateIndex: stateIndex
            }),()=>{
                var baseView = document.getElementsByClassName('af-class-quote-view')[0]
                baseView.classList.remove('no-scroll')

                //determine if there is a promo code
                const urlParams = new URLSearchParams(window.location.search);
                const quoteID = urlParams.get('promo_id');
                if(quoteID !== null){
                    this.setState({promoInput: quoteID},()=>{
                        this.getCoupon();
                    })
                }
                else{
                    this.updateTotal()
                }
            })
        })
        .catch(error => {
            this.setState(()=>({
                formData: null
            }),()=>{
                helperFunctions.handleError(error)
            })            
        })
        .then(()=>{
            PubSub.publish.is_loading(false)
        });
    }

    submitQuote(){
        if(this.state.formData === null){
            window.location.reload()
        }

        PubSub.publish.is_loading(true)
        const url = 'customer/create-quote';
        const quoteData = { 
            email: this.state.formData.email, 
            line_items: [
                {
                    price: quoteOptions.poster_maker.price_id
                },
                {
                    price: quoteOptions.supplies[this.state.supplies].price_id
                },
                {
                    price: quoteOptions.warranty[this.state.warranty].price_id
                },
                {
                    price: installPrices[this.state.stateIndex].price_id
                }
            ]        
        };
        if(quoteOptions.folio[this.state.folio].price_id !== ""){
            quoteData.line_items.push({
                price: quoteOptions.folio[this.state.folio].price_id
            })
        }

        if(this.state.coupon){
            quoteData.coupon = this.state.coupon.id
        }
    
        axios({
            headers:{
            'Content-Type': 'application/json',
            'Authorization': "None",
            },
            method: "POST",
            url: baseURL + url,
            data: JSON.stringify(quoteData),
        })
        .then(response => {
            PubSub.publish.is_loading(false)
            if(response.status  === 200){
                window.location.href = '/success?quote_id=' + response.data.quote_id;
            }
        })
        .catch(error => {
            helperFunctions.handleError(error)
        })
        .then(()=>{
            PubSub.publish.is_loading(false)
        });
    }

    createCard(item, index, type){
        var value = this.getValue(type, item.price, index)
        return(
            <div className={"card " + ((index === this.state[type])?"selected":"")} value={index}  key={type + "_" + index} onClick={(el)=>{
                if(type === "supplies"){
                    this.setState(()=>({
                        supplies: index
                    }))
                }        
            }}>
                <div className="card-header">{item.item}</div>
                <div className="card-body">{item.description}</div>
                <div className="card-footer">
                    {item.default && <div className="recommended-label">(Recommended)</div>}
                    <div className="card-value">{value}</div>
                </div>
            </div>
        )
    }

    createListOption(item, index, type){
        var value = this.getValue(type, item.price, index)
        return(
            <div className = {"radio-item " + ((index === this.state[type])?"selected":"")} key={type + "_" + index} onClick={()=>{this.updateListOption(type, index)}}>
                <input type="radio" id={item.item} name={item.item} value={index}  checked={index === this.state[type]} onChange={()=>{this.updateListOption(type, index)}} />
                <label htmlFor={item.item}>{(item.default && type === "warranty")?(item.item + " (Recommended)"):item.item}</label>
                <label className="price-label" htmlFor={item.item}>{value}</label>
            </div>
        )
    }
    
    updateListOption(type, index){
        if(type === "warranty"){
            this.setState(()=>({
                warranty: index
            }))
        }
        else if(type === "folio"){
            this.setState(()=>({
                folio: index
            }))
        }
    }

    getValue(type, price, index){
        var value = ""
        if(index === this.state[type]){
            value = "Selected"
        }
        else{
            var currentValue = quoteOptions[type][this.state[type]].price
            value = price - currentValue;
            value = ((value < 0)? ("- $"+Math.abs(value)):"+ $"+value);
        }

        return(value)
    }

    componentDidMount(){
        var baseView = document.getElementsByClassName('af-class-quote-view')[0]
        baseView.classList.add('no-scroll')
        PubSub.subscribe.quote_form_submitted((event, info)=>{
            this.setState(()=>({
                formData: info
            }),()=>{    
                window.dataLayer.push({
                    event: 'lead_form_submitted',
                    conversionUrl: window.location.href,
                    });
                this.createCustomer();   
            })
        })
    }

    componentDidUpdate(prevProps, prevState){
        if ((prevState.supplies !== this.state.supplies) || (prevState.installation !== this.state.installation) || (prevState.warranty !== this.state.warranty) || (prevState.folio !== this.state.folio)) {
           this.updateTotal()
        }
    }

    updateTotal(){
        var newTotal = 0;
        newTotal = quoteOptions.poster_maker.price + quoteOptions.supplies[this.state.supplies].price +  installPrices[this.state.stateIndex].price + quoteOptions.warranty[this.state.warranty].price + quoteOptions.folio[this.state.folio].price

        if(this.state.coupon){
            if(this.state.coupon.amount_off !== null){
                newTotal -= (this.state.coupon.amount_off/100);
            }
            else if(this.state.coupon.percent_off !== null){       
                //take percentage off for install price                  
                newTotal -= (installPrices[this.state.stateIndex].price * (this.state.coupon.percent_off/100));
            }
            
        }
        this.setState(()=>({
            total: newTotal
        }))
    }

    getCoupon(){
        PubSub.publish.is_loading(true)
        const url = 'quote/coupon';

        axios({
            headers:{
            'Content-Type': 'application/json',
            'Authorization': "None",
            },
            method: "POST",
            url: baseURL + url,
            data: JSON.stringify({
                coupon_id: this.state.promoInput.toUpperCase()
            })
        })
        .then(response => {
            PubSub.publish.is_loading(false)
            document.getElementById("promo_input").blur()
            this.setState({coupon: response.data.coupon},()=>{
                this.updateTotal()
            })

        })
        .catch(error => {
            PubSub.publish.is_loading(false)
            helperFunctions.handleError(error)
            this.setState({promoError: error.response.data.error})
        })
    }
}



export default QuotePageController