/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import ContactComponentView from './ContactComponentView'
import NavComponentView from './NavComponentView'
import FooterContainerSmView from './FooterContainerSmView'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=644a976732488f10065f1cde").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class IndexView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/IndexController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = IndexView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6453c335a580288091c9c376'
    htmlEl.dataset['wfSite'] = '644a976732488f10065f1cde'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = IndexView.Controller !== IndexView ? transformProxies(this.props.children) : {
      'hero-quote-button': [],
      'package-quote-button': [],
      'package-contact-link': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/pss-quotes.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <ContactComponentView.Controller />
            <NavComponentView.Controller />
            <div className="af-class-section-6">
              <div className="af-class-small-container w-container">
                <div className="af-class-columns-2 w-row">
                  <div className="af-class-column-81 w-col w-col-6">
                    <div className="af-class-upper-case-label af-class-white af-class-header">POSTER MAKER</div>
                    <div className="af-class-pm-header">
                      <div className="af-class-h2 af-class-header af-class-white af-class-slide"><strong>Make posters for less than $4 each</strong></div>
                    </div>
                    <p className="af-class-paragraph-4 af-class-header af-class-white">Engage your students while protecting the school’s budget. With PSS Poster Makers, your school will create engaging posters in-house for less than $4. A similar print would cost $20+ at your local print shop. </p>
                    <div className="af-class-div-block-61">
                      {map(proxies['hero-quote-button'], props => <a href="#" {...{...props, className: `af-class-white-button-3 af-class-header w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Start Your Quote</React.Fragment>}</a>)}
                    </div>
                  </div>
                  <div className="af-class-column-76 w-col w-col-6" />
                </div>
              </div>
            </div>
            <div className="af-class-bsb-selection-1">
              <h1 className="af-class-h5-2 af-class-center af-class-slide">Trusted by over 2,500 schools!⭐</h1>
              <div className="af-class-bsb-grid-1">
                <div className="af-class-bsb-wrap-wrap">
                  <a href="#" className="af-class-bsb-pic-1 w-inline-block"><img src="images/ann_1ann.png" loading="lazy" alt="testimonial 1" className="af-class-bsb-pic-1-1" /></a>
                  <div className="af-class-bsb-wrap-text-1">
                    <div className="af-class-bsb-text-1-1"><strong>- Ann S.</strong><br /></div>
                    <div className="af-class-bsb-text-1-2">School Librarian, Clover High Schooll</div>
                  </div>
                </div>
                <div className="af-class-bsb-wrap-text">
                  <div className="af-class-paragraph-4">"“The customer service at PSS is truly amazing. From quickly putting together a quote &nbsp;to delivering during a pandemic, to tailoring a virtual training session just for me – they go the extra mile – and then some – for customers. I am so appreciative!”</div>
                </div>
              </div>
              <div className="af-class-div-block-89">
                <div className="af-class-bsb-wrap-2">
                  <div className="af-class-div-block-57">
                    <div id="w-node-b2471f54-d9a0-0759-1310-8837aa466094-91c9c376" className="af-class-bsb-grid-1 af-class-testimonial">
                      <div className="af-class-bsb-wrap-wrap">
                        <a href="#" className="af-class-bsb-pic-1 w-inline-block"><img src="images/testimonial_angie_1testimonial_angie.png" loading="lazy" alt="testimonial 2" className="af-class-bsb-pic-1-1" /></a>
                        <div className="af-class-bsb-wrap-text-1">
                          <div className="af-class-bsb-text-1-1"><strong>- Angie T.</strong></div>
                          <div className="af-class-bsb-text-1-2">Secretary, Northwest Cabarrus High School</div>
                        </div>
                      </div>
                      <div className="af-class-bsb-wrap-text">
                        <div className="af-class-paragraph-4">"I have used the Poster Maker for hallways, events, and sports promotions for students and parents. &nbsp;I have really enjoyed working with the Learn OnDemand System and the awesome staff at PSS!"</div>
                      </div>
                    </div>
                  </div>
                  <div id="w-node-b2471f54-d9a0-0759-1310-8837aa4660a1-91c9c376">
                    <div id="w-node-b2471f54-d9a0-0759-1310-8837aa4660a2-91c9c376" className="af-class-bsb-grid-1 af-class-_3rd">
                      <div className="af-class-bsb-wrap-wrap">
                        <a href="#" className="af-class-bsb-pic-1 w-inline-block"><img src="images/Mask-group-1.png" loading="lazy" alt="testimonial 3" className="af-class-bsb-pic-1-1" /></a>
                        <div className="af-class-bsb-wrap-text-1">
                          <div className="af-class-bsb-text-1-1">Robin W.<br /></div>
                          <div className="af-class-bsb-text-1-2">Teacher Librarian, East Mecklenburg HS</div>
                        </div>
                      </div>
                      <div className="af-class-bsb-wrap-text">
                        <div className="af-class-paragraph-4">"A poster printer is invaluable in this day of visual graphics. We know as educators things have to be eye catching and informative to catch the interest of our students - that's society today, the infographic is king."</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FooterContainerSmView.Controller />
            <div className="af-class-section-23">
              <div className="af-class-container-small-2 w-container">
                <div className="af-class-main-column-2 af-class-inverse w-row">
                  <div className="af-class-column-95 w-col w-col-6">
                    <div className="af-class-div-block-74">
                      <div className="af-class-upper-case-label">What you get</div>
                      <h1 className="af-class-h5-2 af-class-bold"><strong className="af-class-h5-2 af-class-bold">The PSS Poster Maker System</strong></h1>
                      <p className="af-class-paragraph-4 af-class-slide">Instead of asking your IT team to build a poster maker system, partner with the top K-12 poster maker provider in the US. Over the last 27+ years, PSS has assembled the best components to ensure making posters is easy and effortless. The system includes:<br />‍<br />• 24” Poster Maker Printer<br />• PC Workstation<br />• Pre-installed Software<br />• High-resolution Scanner<br />• Set of Ink<br />•<strong> </strong>One Year Warranty</p>
                      {map(proxies['package-quote-button'], props => <a href="#" {...{...props, className: `af-class-blue-button-5 w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Get a Quote</React.Fragment>}</a>)}
                      <div className="af-class-caption-4">Using Title 1 or title 3 funds? {map(proxies['package-contact-link'], props => <a href="#" {...{...props, className: `af-class-link-2 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Contact Us </React.Fragment>}</a>)}to learn more!</div>
                    </div>
                  </div>
                  <div className="af-class-column-96 w-col w-col-6"><img src="images/system.png" loading="lazy" srcSet="images/system-p-500.png 500w, images/system-p-800.png 800w, images/system.png 875w" sizes="100vw" alt="poster maker system" className="af-class-image-22" /></div>
                </div>
              </div>
              <div className="af-class-padding-vertical af-class-padding-xxlarge" />
            </div>
          </div>
        </span>
      </span>
    )
  }
}

export default IndexView

/* eslint-enable */