import React from "react";
import Navbar from "../views/NavComponentView";

class NavComponentController extends React.Component{
    state = {}
    render(){
        return(
        <Navbar>
            <logo-button onClick={()=>{window.open("https://pssk12.com","_blank")}}/>
        </Navbar>)
    }

    handleClick(path){
        if(path === "shop"){
            window.open("https://shop.pss.co", "_blank");
        }
        else{
            window.location.pathname = "/" + path
        }
    }

}

export default NavComponentController