import React, { Component } from 'react';
import PubSub from '../pubsub';

class LoadingAnimation extends Component {
    constructor(props){
        super(props)
        this.state={
            isLoading: false
        }
    }
    render(){
        return (
            <div id="loading_overlay" className="loading-overlay" style={{display: this.state.isLoading? "flex":"none"}}>
                <img className="loading-animation" src="/img/loading_animation.gif" alt="Loading some good stuff!" />
            </div>
        );
    }

    componentDidMount(){
        PubSub.subscribe.is_loading((event, info)=>{
            this.setState(()=>({
                isLoading: info
            }))
        });
    }
}

export default LoadingAnimation;