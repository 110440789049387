/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class ContactComponentView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/ContactComponentController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = ContactComponentView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    /* View has no WebFlow data attributes */

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = ContactComponentView.Controller !== ContactComponentView ? transformProxies(this.props.children) : {
      'email-link': [],
      'phone-link': [],
      'contact-link': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/pss-quotes.webflow.css);
        ` }} />
        <span className="af-view">
          <div data-animation="default" data-collapse="medium" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="af-class-top-nav w-nav">
            <div className="af-class-top-narrow-nav w-container">
              {map(proxies['email-link'], props => <a href="#" {...{...props, className: `af-class-nav-link-top w-nav-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>support@pssk12.com</React.Fragment>}</a>)}
              {map(proxies['phone-link'], props => <a href="#" {...{...props, className: `af-class-nav-link-top w-nav-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>1-800-485-9722</React.Fragment>}</a>)}
              {map(proxies['contact-link'], props => <a href="#" {...{...props, className: `af-class-nav-link-top w-nav-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Contact Us</React.Fragment>}</a>)}
            </div>
          </div>
        </span>
      </span>
    )
  }
}

export default ContactComponentView

/* eslint-enable */