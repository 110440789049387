/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class FooterContainerSmView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/FooterContainerSmController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = FooterContainerSmView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    /* View has no WebFlow data attributes */

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = FooterContainerSmView.Controller !== FooterContainerSmView ? transformProxies(this.props.children) : {
      'pp-link': [],
      'tos-link': [],
      'support-link': [],
      'ig-link': [],
      'tt-link': [],
      'yt-link': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/pss-quotes.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-section-20">
            <div className="af-class-footerlink">@PSS</div>
            <div>
              {map(proxies['pp-link'], props => <div {...{...props, className: `af-class-footerlink ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Privacy Policy </React.Fragment>}</div>)}
              {map(proxies['tos-link'], props => <div {...{...props, className: `af-class-footerlink ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Terms of Service</React.Fragment>}</div>)}
            </div>
            <div className="af-class-div-block-65">
              {map(proxies['support-link'], props => <div {...{...props, className: `af-class-footerlink ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>support@pssk12.com</React.Fragment>}</div>)}{map(proxies['ig-link'], props => <img src="images/insta.svg" loading="lazy" alt {...{...props, className: `af-class-footericon ${props.className || ''}`}}>{props.children}</img>)}{map(proxies['tt-link'], props => <img src="images/tik-tok.svg" loading="lazy" alt {...{...props, className: `af-class-footericon ${props.className || ''}`}}>{props.children}</img>)}{map(proxies['yt-link'], props => <img src="images/youtube.svg" loading="lazy" alt {...{...props, className: `af-class-footericon ${props.className || ''}`}}>{props.children}</img>)}
            </div>
          </div>
        </span>
      </span>
    )
  }
}

export default FooterContainerSmView

/* eslint-enable */