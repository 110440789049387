import React from "react";
import IndexView from "../views/IndexView";

class IndexController extends React.Component{
    render(){
        return(
            <IndexView>
                <hero-quote-button onClick={()=>{
                    window.location.pathname="/create-quote";
                }}/>
                <package-quote-button onClick={()=>{
                    window.location.pathname="/create-quote";
                }}/>
                <package-contact-link href="mailto:info@pssk12.com"/>
            </IndexView>
        )
    }
}

export default IndexController